import {useState} from 'react';
import {observer} from 'mobx-react-lite';

import {Button, SidebarMenu, Typography} from '@components/index';
import {COLORS, COLORS_REDESIGN} from '@shared/const/appPalette';
import {SIDEBAR_IS_COMPACT} from '@shared/const/localStorage';
import {
  AuthIcon,
  ChevronDoubleIcon,
  ConsultationIcon,
  GroupsIcon,
  KnowledgeBaseIcon,
  PriceMonitoringIcon,
  PriceMonitoringSidebarIcon,
  ProductIcon,
  RocketIcon,
  SmartSellerIcon,
  SummaryAnalyticsIcon,
  TelegramIcon,
} from '@src/components/common/icons';
import {useCurrentTariff} from '@src/hooks/useCurrenTariff';
import {RESOURCE_URLS} from '@src/shared/const/resource-urls';
import {
  getRouteAnalytics,
  getRouteLogin,
  getRouteProducts,
  getRouteRepricer,
  getRouteRules,
  getRouteSummaryAnalytics,
} from '@src/shared/const/router';

import {useStore} from '../../../stores';

import {useEmotionWrapper} from './sidebar.s';

export const Sidebar = observer(() => {
  const [isCompact, setIsCompact] = useState(!!localStorage.getItem(SIDEBAR_IS_COMPACT));
  const {classes, cx} = useEmotionWrapper({isCompact});
  const {user, utils, mpAccounts} = useStore();
  const {isFreeTariff} = useCurrentTariff();

  const ruleNotifyCount = () => {
    const findIndex = utils.newRuleNotify.findIndex((item) => item.id === mpAccounts.current?.id);
    {
      if (findIndex === -1) {
        return undefined;
      } else {
        return utils.newRuleNotify[findIndex].count;
      }
    }
  };

  const handleClickCompact = () => {
    setIsCompact((prevState) => {
      if (!prevState) {
        localStorage.setItem(SIDEBAR_IS_COMPACT, (!prevState).toString());
      } else {
        localStorage.removeItem(SIDEBAR_IS_COMPACT);
      }
      return !prevState;
    });
  };

  const privateMenuList = {
    primary: [
      {
        title: 'Товары',
        path: getRouteProducts(),
        icon: <ProductIcon size="20" />,
        isPrivate: true,
        dataCy: 'sidebar-item-products',
      },
      {
        title: 'Группы',
        path: '/groups',
        icon: <GroupsIcon size="20" />,
        isPrivate: true,
        isMpAcc: true,
        id: 'onboarding-sidebar-groups',
        dataCy: 'sidebar-item-groups',
        disabled: isFreeTariff,
      },
      {
        title: 'Буст продаж',
        path: getRouteRules(),
        icon: <RocketIcon size="20" />,
        isPrivate: true,
        countNotify: ruleNotifyCount(),
        isMpAcc: true,
        id: 'onboarding-sidebar-sales-boost',
        dataCy: 'sidebar-item-sales-boost',
        disabled: isFreeTariff,
      },
      {
        title: 'Ценообразование',
        customTooltip: 'Ценообразование',
        path: getRouteRepricer(),
        icon: <PriceMonitoringSidebarIcon size="22" />,
        isPrivate: true,
        id: 'onboarding-sidebar-price-monitoring',
        dataCy: 'sidebar-item-price-monitoring',
        disabled: isFreeTariff,
      },
      {
        title: 'Аналитика',
        path: getRouteAnalytics(),
        icon: <PriceMonitoringIcon size="20" />,
        isPrivate: true,
        id: 'onboarding-sidebar-analytics',
        dataCy: 'sidebar-item-analytics',
        params: [
          {mpAcc: mpAccounts.current?.uuidSk},
          {user: user.data.id.toString()},
          {tariffId: mpAccounts.current?.tariff?.tariffId.toString()},
        ],
        disabled: isFreeTariff,
      },
      {
        title: 'Свод. аналитика',
        path: getRouteSummaryAnalytics(),
        icon: <SummaryAnalyticsIcon size="20" />,
        isPrivate: true,
        isHide: !mpAccounts.current?.analyticsDashboardUrl,
        id: 'onboarding-sidebar-analytics',
        dataCy: 'sidebar-item-analytics',
        disabled: isFreeTariff,
      },
    ],
    secondary: [
      {
        title: 'Консультация',
        path: RESOURCE_URLS.SUPPORT_TELEGRAM,
        isNewWindow: true,
        icon: <ConsultationIcon size="20" />,
        isPrivate: true,
        isMpAcc: true,
      },
      {
        title: 'База знаний',
        isNewWindow: true,
        path: RESOURCE_URLS.INSTRUCTION,
        icon: <KnowledgeBaseIcon size="20" />,
        isPrivate: true,
      },
      {
        title: 'Telegram канал',
        isNewWindow: true,
        path: RESOURCE_URLS.TELEGRAM_CHANEL,
        icon: <TelegramIcon size="20" />,
        isPrivate: false,
        isMpAcc: false,
      },
    ],
  };

  const publicMenuList = {
    primary: [
      {
        title: 'Авторизация',
        isNewWindow: true,
        path: getRouteLogin(),
        icon: <AuthIcon size="20" color={COLORS_REDESIGN.DARK_NIGHT_BLUE} />,
        isPrivate: false,
      },
    ],
  };

  const customIconButton = (
    <Button
      style={{
        backgroundColor: COLORS.BLACK_6,
        border: 'none',
        boxShadow: 'none',
      }}
      color={COLORS.DARK_20}
      icon={<ChevronDoubleIcon color={COLORS.BLACK_40} />}
      variant="outlined"
      onClick={handleClickCompact}
    />
  );

  return (
    <div className={classes.root}>
      <div className={cx(classes.isCompactSide)}>
        <a onClick={() => window.open('https://smartseller.me/', '_blank')}>
          <SmartSellerIcon className={classes.smartSellerIcon} />
        </a>
        <div className={classes.menuIcon}>{customIconButton}</div>
      </div>
      <div className={classes.menuBlock}>
        <SidebarMenu isCompact={isCompact} menuList={user.isAuth ? privateMenuList : publicMenuList} />
      </div>
      <div onClick={() => window.open(RESOURCE_URLS.OFERTA, '_blank')} className={classes.oferta}>
        <Typography size={11} color={COLORS.DARK_50}>
          Оферта
        </Typography>
      </div>
    </div>
  );
});
