import {type FC, useEffect} from 'react';
import {observer} from 'mobx-react-lite';

import {useTour} from '@reactour/tour';
import {getJWTToken} from '@shared/utils/getJWTToken';
import {IS_VIEWED_ONBOARDING_TOUR} from '@src/shared/const/localStorage';

import {AppLoader} from './components/app-loader/app-loader';
import {getRemainingTokenTime} from './utils/get-remaining-token-time';
import {tokenExpirationTimer} from './utils/token-expiration-timer';
import {useStore} from '../../../stores';

interface IAuthWrapper {
  children: React.ReactNode;
}

export const AuthWrapper: FC<IAuthWrapper> = observer(({children}) => {
  const {mpAccounts, mpItems, productGroups, rules, user, tariffs, utils} = useStore();
  const {setIsOpen} = useTour();
  const isViewedOnboarding = localStorage.getItem(IS_VIEWED_ONBOARDING_TOUR) === 'true';

  const getUserMarketPlaceAccountsData = async () => {
    if (mpAccounts.current?.id) {
      Promise.all([
        mpItems.getShopsByAccountId(mpAccounts.current.id),
        mpItems.getVendorsByAccountId(mpAccounts.current?.id),
        mpItems.getCategoriesByAccountId(mpAccounts.current.id),
        mpItems.getStrategiesByAccountId(mpAccounts.current.id),
        productGroups.getGroupList(mpAccounts.current.id),
        rules.getRulesList(mpAccounts.current?.id),
        tariffs.getTariffsList(mpAccounts.current?.id),
        mpAccounts.getRegionList(mpAccounts.current?.id),
      ]);
    }
  };

  const onRefreshToken = async () => {
    const refreshToken = getJWTToken().refreshToken;
    if (refreshToken) {
      try {
        await user.refreshToken();
        await user.getUserInfo();
      } catch {
        user.logout();
      }
    }
  };

  const initUser = async () => {
    const {accessToken, refreshToken} = getJWTToken();
    const expAccessToken = getRemainingTokenTime(accessToken);

    try {
      if ((!accessToken || expAccessToken <= 2) && refreshToken) {
        await onRefreshToken();
      }

      if (!user.isAuth) await user.getUserInfo();
    } catch {
      user.logout();
    }

    if (user.isAuth) {
      await mpAccounts.getMpAccountList();
    }
    user.setIsInitApp(true);
  };

  useEffect(() => {
    initUser();
    tokenExpirationTimer(onRefreshToken);
  }, [user.isAuth]);

  useEffect(() => {
    getUserMarketPlaceAccountsData();
  }, [mpAccounts.current?.id]);

  useEffect(() => {
    if (
      mpAccounts.current?.id &&
      mpAccounts.current.tariff?.isTrial &&
      !isViewedOnboarding &&
      !utils.isOpenOfferTrialModal
    ) {
      setIsOpen(true);
    }
  }, [mpAccounts.current?.id, utils.isOpenOfferTrialModal]);

  return !user._init ? <AppLoader /> : children;
});
